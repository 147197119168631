<template>
  <div>
    <!-- Phone Numbers Configured Table -->
    <div class="vx-row">
      <div class="vx-col w-full mt-8">
        <vx-card>
          <!-- Table Title -->
          <div class="table-title">
            <h2>Phone Numbers Configured</h2>
            <!-- <vs-button
              @click="popupActive=true"
              color="gray"
              type="flat"
              icon-pack="feather"
              icon="icon-help-circle"
              class="p-0 help-btn"
            ></vs-button> -->    
            <div class="actions-button">              
                  <vx-tooltip color="actionbutton" text="EXCEL" class="float-left">
                     <vs-button class="bg-actionbutton" type="filled">
                        <download-excel
                           class="btn btn-default"
                           :data="excelData"
                           :exportFields="excelFields"
                           worksheet="Call Tracking Numbers"
                           name="Call Tracking Numbers.xls"
                        >
                           <img src="@/assets/images/excel.svg" />
                        </download-excel>
                     </vs-button>
                  </vx-tooltip>
            </div>                       
          </div> 
          <div class="add-new-number mb-4">
                <vs-button
                  title="Add New Phone Number"
                  @click="addphoneActive=true, addOrEditTrackingPhoneNumberTitle='Create New Tracking Phone Number'"              
                  type="filled"
                  icon-pack="feather"
                  icon="icon-plus"
                  class="small-btn mr-3 bg-actionbutton"
                  :disabled="isAddNewPhoneNumber"
                >Add New Phone Number</vs-button>
            <p class="py-2" v-if="!this.$store.state.isReseller">
              Your account includes 5 local numbers and 100 minutes of use.
              <a
                  href="#"
                  title="View Call Tracking Rates"
                  @click="calltrackingActive = true"
              >View call tracking rates</a>
            </p>
          </div>       
          <div class="common-table">      
            <div class="table-row-select">
               <vs-select label="Show Entries" v-model="perPage" class="show-select" autocomplete>
               <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item,index) in recordList"
               />
               </vs-select>
            </div>
            <vs-table
               :data="provisionedPhoneNumbers"
               search
               :max-items="perPage"
               pagination
               ref="tableData"
               id="callTrackingNumbers"
            >
              <template slot="thead">
                <vs-th></vs-th>
                <vs-th sort-key="Campaign_Name">{{ LabelConstant.tableHeaderLabelCampaignName }}</vs-th>
                <vs-th sort-key="Number">{{ LabelConstant.tableHeaderLabelTrackingNumber }}</vs-th>
                <vs-th sort-key="Forward_To">{{ LabelConstant.tableHeaderLabelForwardCallTo }}</vs-th>
                <vs-th sort-key="IsActive">{{ LabelConstant.tableHeaderLabelStatus }}</vs-th>
                <vs-th sort-key="Record_Call">{{ LabelConstant.tableHeaderLabelRecordCall }}</vs-th>
                <vs-th sort-key="sortableDate">{{ LabelConstant.tableHeaderLabelDateCreated }}</vs-th>
              </template>
              <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>
                    <div class="table-action">
                      <vs-button
                        @click="getIndividualCallTrackerForEdit(tr.Id), addOrEditTrackingPhoneNumberTitle='Edit Tracking Phone Number Configuration'"                      
                        title="Edit"
                        type="filled"
                        icon-pack="feather"
                        icon="icon-edit"
                        class="mr-2 float-left"
                        color="primary"
                      ></vs-button>
                      <vs-button
                        @click="deletepopupActive=true, getIndividualCallTrackerForDelete(tr.Id)"
                        title="Delete"
                        color="danger"
                        type="filled"
                        icon-pack="feather"
                        icon="icon-trash-2"
                        class="mr-0"
                      ></vs-button>
                    </div>
                  </vs-td>
                  <vs-td :data="tr.Campaign_Name" width="200">{{tr.Campaign_Name}}</vs-td>
                  <vs-td :data="tr.Number">{{tr.Number}}</vs-td>
                  <vs-td :data="tr.Forward_To">{{tr.Forward_To}}</vs-td>
                  <vs-td :data="tr.IsActive">
                    <vs-chip color="success" class="text-center" v-if="tr.IsActive">Active</vs-chip>
                    <vs-chip color="gray" class="text-center" v-else>Disabled</vs-chip>
                  </vs-td>
                  <vs-td :data="tr.Record_Call">
                    <vs-chip color="primary" class="text-center" v-if="tr.Record_Call">Recorded</vs-chip>
                    <vs-chip color="danger" class="text-center" v-else>Not Recorded</vs-chip>
                  </vs-td>
                  <vs-td :data="tr.sortableDate">{{tr.Date_Created}}</vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <p class="table-data-entries-details" v-if="!isSearch && isTableRecordEntriesDisplay">Showing {{showingDataFrom}} to {{showingDataTo}} of {{provisionedPhoneNumbers.length|tableRecordNumberFormatter}} entries</p>
            <p class="table-data-entries-details" v-if="isSearch && isTableRecordEntriesDisplay">Showing {{showingDataFrom}} to {{showingSearchedDataTo}} of {{totalSearchedData|tableRecordNumberFormatter}} entries (filtered from {{provisionedPhoneNumbers.length|tableRecordNumberFormatter}} entries)</p>
            <p class="table-data-entries-details" v-if="!isTableRecordEntriesDisplay">Showing 0 to 0 of 0 entries </p>
          </div>
          <!--Table Selcet Row-->
        </vx-card>
      </div>
    </div>
    <!-- Help popup -->
    <help-modal :popupActive="popupActive" @close="closepopup"></help-modal>
    <!-- Delete Popup -->
    <vs-popup class="delete-popup" :active.sync="deletepopupActive">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="popup-icon">
              <feather-icon icon="Trash2Icon"></feather-icon>
            </div>
            <h4>Delete Call Tracking Number?</h4>
            <span class="deleted-no">{{deletableTrakingPhoneNumber}}</span>
            <p>You cannot recover this number after it has been deleted.</p>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button type="filled" @click="deleteTrackingNumber()" color="danger">{{ LabelConstant.buttonLabelDelete }}</vs-button>
        <vs-button
          @click="cancelTrakingPhoneNumberDeletion()"
          color="grey"
          type="border"
          class="ml-4 grey-btn"
        >{{ LabelConstant.buttonLabelCancel }}</vs-button>
      </div>
    </vs-popup>
    <!-- Add New Phone Number -->
    <vs-popup
      class="add-new"
      :title="addOrEditTrackingPhoneNumberTitle"
      :active.sync="addphoneActive"
      >
      <div class="add-track-no">
        <vs-progress :percent="progressBar" color="secondary"></vs-progress>
        <!-- Step1: Online OR Offline -->
        <div class="first-step-track" v-if="activeStep == 1">
          <div class="tarcking-no">
            <div class="vx-row">
              <div class="vx-col w-full mb-8"><p class="text-center text-lg font-medium mb-0">Please tell us how you will use this tracking number.</p></div>
              <div class="vx-col sm:w-1/2 w-full mb-12 md:mb-8 mb:mt-0">
                <div class="web-icons">
                  <div class="web-img"><span><img src="../../assets/images/online.svg"></span></div>
                  <vs-button class="small-btn mt-6 bg-actionbutton" @click="addNewNumberData.IsUsedOnline = true, nextStep(2)">Yes I'll use it online</vs-button>
                  <p class="mt-6 mb-0">e.g. Website, Email, Adwords</p>
                </div>
              </div>
              <div class="vx-col sm:w-1/2 w-full mb-0 md:mb-8">
                <div class="web-icons">
                  <div class="web-img"><span><img src="../../assets/images/offline.svg"></span></div>
                  <vs-button class="small-btn mt-6 bg-actionbutton" @click="addNewNumberData.IsUsedOnline = false, addNewNumberData.IsDNI = false, nextStep(4)">Yes I'll use it Offline</vs-button>
                  <p class="mt-6 mb-0">e.g. Print, Direct Mail, Television</p>
                </div>
              </div>
            </div>
          </div>         
        </div>
        <!-- Step2: Yes, use DNI OR No, just track -->
        <div class="first-step-track" v-if="activeStep == 2">          
          <div class="vx-row">
              <div class="vx-col w-full mb-8 "><p class="text-center text-lg font-medium  mb-0">Do you want us to update your website dynamically with this number?</p></div>
              <div class="vx-col sm:w-1/2 w-full mb-12 md:mb-8 mb:mt-0">
                <div class="web-icons">
                  <div class="web-img"><span><img src="../../assets/images/dni.svg"></span></div>
                  <vs-button class="small-btn mt-6 bg-actionbutton" @click="addNewNumberData.IsDNI = true, nextStep(3)">Yes, use DNI</vs-button>                  
                  <p class="mt-6 mb-0">Use Dynamic Number Insertion (DNI) to automatically update my website. This requires installing some javascript code on your site.</p>
                </div>
              </div>
              <div class="vx-col sm:w-1/2 w-full mb-0 md:mb-8">
                <div class="web-icons">
                  <div class="web-img"><span><img src="../../assets/images/web-ad.svg"></span></div>
                   <vs-button class="small-btn mt-6 bg-actionbutton" @click="addNewNumberData.IsDNI = false, nextStep(4)">No, just track</vs-button>                  
                  <p class="mt-6 mb-0">Just provide a number that I can use in my online ads and email signatures. No code installation required.</p>
                </div>
              </div>
          </div>          
        </div>
        <!-- Step3: Create Pool OR Create Single -->        
        <div class="first-step-track" v-if="activeStep == 3">
            <div class="vx-row">
              <div class="vx-col w-full mb-8"><p class="text-center text-lg font-medium mb-0">Would you like to create a pool of numbers for dynamic tracking, or just track at a campaign level with a single number?</p></div>
              <div class="vx-col sm:w-1/2 w-full mb-12 md:mb-8 mb:mt-0">
                <div class="web-icons">
                  <div class="web-img"><span><img src="../../assets/images/track.svg"></span></div>
                  <vs-button class="small-btn mt-6 bg-actionbutton" @click="addNewNumberData.IsPool = true, nextStep(4)" >Create Pool</vs-button>
                  <p class="mt-6 mb-0">Using a pool of numbers will allow you to track active visitors to your site. Each visitor will receive a different number from your pool for their session. This will allow you to associate their online behavior with their call.</p>
                </div>
              </div>
              <div class="vx-col sm:w-1/2 w-full mb-0 md:mb-8">
                <div class="web-icons">
                  <div class="web-img"><span><img src="../../assets/images/calls-receive.svg"></span></div>
                  <vs-button class="small-btn mt-6 bg-actionbutton" @click="addNewNumberData.IsPool = false, nextStep(4)" >Create Single</vs-button>
                  <p class="mt-6 mb-0">No, use a single number. You can display different numbers based on the source of the visitor. By setting the source, you will be able to track at a campaign level how many calls you received.</p>
                </div>
              </div>
          </div>
        </div>
        <!-- Step4: Name and Destination -->
        <div class="first-step-track" v-if="activeStep == 4">
          <h3>Name and Destination</h3>
          <div class="form-area">
            <form data-vv-scope="fourthStepForm">
              <div class="vx-row mb-6">
                <div class="vx-col w-full mb-6">
                  <span class="input-label" v-show="addNewNumberData.IsPool">Pool Name</span>
                  <span class="input-label" v-show="!addNewNumberData.IsPool">Campaign Name</span>
                  <vs-input
                    class="w-full"
                    v-model="addNewNumberData.Campaign_Name"
                    v-validate="'required'"
                    name="campaignName"
                  />
                  <span
                    class="text-danger text-sm error-msg"
                  >{{ errors.first('fourthStepForm.campaignName') }}</span>
                </div>
                <div class="vx-col w-full">
                  <span class="input-label">Forward Call To</span>
                  <div class="add-icon-group">
                    <!-- <vs-input
                      class="add-icon-input"
                      v-model="addNewNumberData.Forward_To"
                      v-validate="'required|digits:10'"
                      name="forwardTo"
                    /> -->
                    <the-mask 
                      class="add-icon-input"
                      v-model="addNewNumberData.Forward_To"
                      mask="###-###-####"
                      :masked='true'
                    />
                    <vs-button
                      color="success"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-plus"
                      class="add-btn"
                      @click="addExtraForwardToPhoneNumber()"
                    ></vs-button>
                    <!-- <span class="text-sm w-full float-left">Please do not add '-' or '()' while entering phone number.</span> -->
                    <!-- <span
                      class="text-danger text-sm error-msg w-full float-left"
                    >{{ errors.first('fourthStepForm.forwardTo') }}</span> -->
                  </div>
                  <div
                    class="add-icon-group"
                    v-for="(extraForwardTo, index) in addNewNumberData.Extra_Forward_To"
                    :key="index"
                  >
                    <!-- <vs-input
                      class="add-icon-input"
                      v-model="addNewNumberData.Extra_Forward_To[index]"
                      v-validate="'required|digits:10'"
                      :name="'phone number '+index"
                    /> -->
                    <the-mask 
                      class="add-icon-input"
                      v-model="addNewNumberData.Extra_Forward_To[index]"
                      mask="###-###-####"
                      :masked='true'
                    />
                    <vs-button
                      color="danger"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-minus"
                      class="add-btn"
                      @click="removeExtraForwardToPhoneNumber(index)"
                    ></vs-button>
                    <!-- <span
                      class="text-danger text-sm error-msg"
                    >{{ errors.first('fourthStepForm.phone number '+index) }}</span> -->
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="flex w-full flex-wrap mb-6" v-if="editMode">
            <vs-checkbox
              class="w-full mb-2"
              v-if="addNewNumberData.IsUsedOnline"
              v-model="addNewNumberData.IsActive"
            >Active (This does not impact billing but deactivates from online use)</vs-checkbox>
            <vs-checkbox
              class="w-full mb-2"
              v-if="!addNewNumberData.IsPool"
              v-model="addNewNumberData.IsUsedOnline"
            >Use Number Online?</vs-checkbox>
            <vs-checkbox
              class="w-full"
              v-if="!addNewNumberData.IsPool && addNewNumberData.IsUsedOnline"
              v-model="addNewNumberData.IsDNI"
            >Use Dynamic Number Insertion?</vs-checkbox>
          </div>
          <vs-button
            v-if="addNewNumberData.IsDNI && !replacementShow"
            @click="replacementShow = !replacementShow"            
            type="filled"
            icon-pack="feather"
            icon="icon-plus"
            class="small-btn"
            color="primary"
          >Show Formatting Options</vs-button>
          <vs-button
            v-if="addNewNumberData.IsDNI && replacementShow"
            @click="replacementShow = !replacementShow"            
            type="filled"
            icon-pack="feather"
            icon="icon-minus"
            class="small-btn"
            color="primary"
          >Hide Formatting Options</vs-button>
          <div class="formatting-options" v-if="replacementShow">
            <h4 class="mb-4">Set your number replacement options:</h4>
            <div class="replace-number">
              <vs-radio
                v-model="addNewNumberData.Replacement_Option"
                vs-value="ReplaceOnlyDestination"
                class="mb-4"
              >Replace only destination number</vs-radio>
              <vs-radio
                v-model="addNewNumberData.Replacement_Option"
                vs-value="ReplaceAll"
                class="mb-4"
              >Replace all phone numbers</vs-radio>
              <vs-radio
                v-model="addNewNumberData.Replacement_Option"
                vs-value="ReplaceExact"
                class="mb-4"
              >Replace only this exact match</vs-radio>
            </div>
            <div class="form-area">
              <div class="vx-row">
                <div class="vx-col w-full md:w-1/2 mb-4 md:mb-0">
                  <form data-vv-scope="fourthStepFormTwo">
                    <span class="input-label">Exact phone number to replace</span>
                    <vs-input
                      class="w-full"
                      v-model="addNewNumberData.Replace_Exact_String"
                      v-validate="'required'"
                      name="replaceExactString"
                    />
                    <span
                      class="text-danger text-sm error-msg"
                    >{{ errors.first('fourthStepFormTwo.replaceExactString') }}</span>
                  </form>
                </div>
                <div class="vx-col w-full md:w-1/2">
                  <span class="input-label">Select number format for replacement</span>
                  <v-select
                    v-model="addNewNumberData.Replacement_Format"
                    :options="replacementOptions"
                    label="label"
                    :reduce="option => option.id"
                    :clearable="false"
                  />
                  <!-- <vs-select v-model="addNewNumberData.Replacement_Format">
                                    <vs-select-item :key="index" :value="item.id" :text="item.label" v-for="(item,index) in replacementOptions" />
                  </vs-select>-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Step5: Replace number when the visitor is from this source -->
        <div class="first-step-track" v-if="activeStep == 5">
          <p>Replace number when the visitor is from this source.</p>
          <div class="replace-source" v-if="addNewNumberData.IsPool">
            <div class="replace-number">
              <vs-radio
                class="mr-0"
                v-model="addNewNumberData.Pool_Track_Type"
                vs-value="trackall"
              >Always replace the number.</vs-radio>
              <vs-radio
                v-model="addNewNumberData.Pool_Track_Type"
                vs-value="trackspecific"
                class="ml-0 mr-0 mt-8"
              >Only replace the phone number when the visitor comes from the sources below</vs-radio>
              <span class="text-danger text-sm">{{stepFiveErrorMessage}}</span>
            </div>
            <ul class="track-list">
              <li>
                <vs-checkbox v-model="addNewNumberData.Pool_Track_Google_PPC">Google PPC</vs-checkbox>
              </li>
              <li>
                <vs-checkbox v-model="addNewNumberData.Pool_Track_Google_Organic">Google Organic</vs-checkbox>
              </li>
              <li>
                <vs-checkbox v-model="addNewNumberData.Pool_Track_Google_Local">Google Local</vs-checkbox>
              </li>
              <li>
                <vs-checkbox v-model="addNewNumberData.Pool_Track_Bing_PPC">Bing PPC</vs-checkbox>
              </li>
              <li>
                <vs-checkbox v-model="addNewNumberData.Pool_Track_Bing_Organic">Bing Organic</vs-checkbox>
              </li>
              <li>
                <vs-checkbox v-model="addNewNumberData.Pool_Track_Bing_Local">Bing Local</vs-checkbox>
              </li>
              <li>
                <vs-checkbox v-model="addNewNumberData.Pool_Track_Yahoo_PPC">Yahoo PPC</vs-checkbox>
              </li>
              <li>
                <vs-checkbox v-model="addNewNumberData.Pool_Track_Yahoo_Organic">Yahoo Organic</vs-checkbox>
              </li>
              <li>
                <vs-checkbox v-model="addNewNumberData.Pool_Track_Yahoo_Local">Yahoo Local</vs-checkbox>
              </li>
            </ul>
          </div>
          <div class="replace-source" v-if="!addNewNumberData.IsPool">
            <div class="replace-number">
              <div class="visit-source">
                <vs-radio
                  v-model="addNewNumberData.Single_Track_Type"
                  vs-value="search"
                  class="ml-0"
                >Search</vs-radio>
                <div
                  class="vx-col w-full flex flex-wrap items-center mb-2 pl-8"
                  v-if="addNewNumberData.Single_Track_Type == 'search'"
                >
                  <span class="input-label mr-4">Visitors from the follow search engines</span>
                  <v-select
                    v-model="addNewNumberData.Single_Track_Search"
                    :options="searchEngineOptions"
                    label="label"
                    :reduce="option => option.id"
                    :clearable="false"
                  />
                  <!-- <vs-select v-model="addNewNumberData.Single_Track_Search">
                                    <vs-select-item :key="index" :value="item.id" :text="item.label" v-for="(item,index) in searchEngineOptions" />
                  </vs-select>-->
                </div>
              </div>
              <div class="visit-source">
                <vs-radio
                  v-model="addNewNumberData.Single_Track_Type"
                  vs-value="referral"
                  class="ml-0"
                >Referrals</vs-radio>
                <div
                  class="vx-col w-full flex flex-wrap items-center mb-2 pl-8"
                  v-if="addNewNumberData.Single_Track_Type == 'referral'"
                >
                  <span class="input-label mr-4">Visitors from</span>
                  <vs-input
                    class="add-icon-input"
                    v-model="addNewNumberData.Single_Track_Referrer"
                  />
                  <span class="text-danger text-sm">{{stepFiveErrorMessage}}</span>
                </div>
              </div>
              <div class="visit-source">
                <vs-radio
                  v-model="addNewNumberData.Single_Track_Type"
                  vs-value="landingpage"
                  class="ml-0"
                >Landing Page</vs-radio>
                <div
                  class="vx-col w-full flex flex-wrap items-center mb-2 pl-8"
                  v-if="addNewNumberData.Single_Track_Type == 'landingpage'"
                >
                  <span class="input-label mr-4">Visitors who land on</span>
                  <vs-input
                    class="add-icon-input"
                    v-model="addNewNumberData.Single_Track_Landing_Page"
                  />
                  <span class="text-danger text-sm">{{stepFiveErrorMessage}}</span>
                </div>
              </div>
              <div class="visit-source">
                <vs-radio
                  v-model="addNewNumberData.Single_Track_Type"
                  vs-value="landingpageparam"
                  class="ml-0"
                >Landing Param</vs-radio>
                <div
                  class="vx-col w-full flex flex-wrap items-center pl-8"
                  v-if="addNewNumberData.Single_Track_Type == 'landingpageparam'"
                >
                  <span
                    class="input-label mr-4"
                  >Visitors who land on a page with the following parameter</span>
                  <vs-input
                    class="add-icon-input"
                    v-model="addNewNumberData.Single_Track_Landing_Page_Param"
                  />
                  <span class="text-danger text-sm">{{stepFiveErrorMessage}}</span>
                </div>
              </div>
              <div class="visit-source">
                <vs-radio v-model="addNewNumberData.Single_Track_Type" vs-value="direct">Direct</vs-radio>
              </div>
              <div class="visit-source">
                <vs-radio
                  v-model="addNewNumberData.Single_Track_Type"
                  vs-value="always"
                >Always Replace</vs-radio>
              </div>
            </div>
          </div>
        </div>
        <!-- Step6: Find Available Number -->
        <div class="first-step-track" v-if="activeStep == 6">
          <h3 v-if="!editMode">Find Available Number</h3>
          <h3 v-if="editMode && addNewNumberData.IsPool">Current Pool Numbers</h3>
          <h3 v-if="editMode && !addNewNumberData.IsPool">Current Numbers</h3>
          <div class="replace-number">
            <vs-radio
              v-model="addNewNumberData.IsTollFree"
              vs-value="false"
              class="mr-8"
              :disabled="editMode"
            >Local</vs-radio>
            <vs-radio
              v-model="addNewNumberData.IsTollFree"
              vs-value="true"
              :disabled="editMode || isFreeTrial"
            >Toll Free</vs-radio>
          </div>
          <div class="form-area">
            <div class="vx-row">
              <div class="vx-col w-full md:w-1/2 mb-6 md:mb-0" v-if="addNewNumberData.IsPool">
                <span class="input-label">Pool Size</span>
                <v-select
                  v-model="addNewNumberData.Pool_Size"
                  :options="poolSizeOptions"
                  label="label"
                  :reduce="option => option.id"
                  :clearable="false"
                />
                <!-- <vs-select v-model="addNewNumberData.Pool_Size" :disabled="editMode">
                                <vs-select-item :key="index" :value="item.id" :text="item.label" v-for="(item,index) in poolSizeOptions"/>
                </vs-select>-->
              </div>
              <div class="vx-col w-full md:w-1/2 mb-6 md:mb-0 relative" v-if="!editMode">
                <div v-if="addNewNumberData.IsTollFree == 'false'">
                  <span class="input-label">Area Code</span>
                  <vs-input class="float-left area-code" v-model="addNewNumberDataArea_Code_New" />
                  <span
                    class="text-danger text-sm float-left w-full error-msg"
                  >{{stepSixErrorMessage}}</span>
                </div>
                <div v-if="addNewNumberData.IsTollFree == 'true'" class="toll-free mb-2 sm:mb-0">
                  <v-select
                    class="float-left"
                    v-model="addNewNumberData.Area_Code_800"
                    :options="tollFreeOptions"
                    label="label"
                    :reduce="option => option.id"
                    v-bind:class="{ disabled: true }"
                    :clearable="false"
                  />
                  <!-- <vs-select v-model="addNewNumberData.Area_Code_800" disabled="true">
                                    <vs-select-item value="844" text="Toll Free"/>
                  </vs-select>-->
                </div>
                <vs-button
                  color="secondary"
                  type="filled"
                  class="float-left ml-2 find-no-btn"
                  @click="findAvailablePhoneNumbers()"
                >Find Numbers</vs-button>
              </div>
            </div>
            <div class="vx-row" v-if="availablePhoneNumbers.length > 0 && !editMode">
              <div class="vx-col w-full mt-8">
                <p class="mb-6" v-if="!addNewNumberData.IsPool">
                  <strong>Please select an available phone number:</strong>
                </p>
                <p class="mb-4" v-if="addNewNumberData.IsPool">
                  <strong>Sample of numbers selected for your pool:</strong>
                </p>
                <div class="select-phone" v-if="!addNewNumberData.IsPool">
                  <vs-radio
                    v-model="addNewNumberData.Number"
                    :vs-value="availablePhoneNumber.Number"
                    v-for="(availablePhoneNumber, index) in availablePhoneNumbers"
                    :key="index"
                  >{{availablePhoneNumber.National_Number}}</vs-radio>
                </div>
                <ul class="number-list">
                  <li
                    v-for="(availablePhoneNumber, index) in availablePhoneNumbers"
                    :key="index"
                    v-if="addNewNumberData.IsPool"
                  >{{availablePhoneNumber.National_Number}}</li>
                </ul>
              </div>
            </div>
            <div class="vx-row" v-if="editMode">
              <div class="vx-col w-full mt-8">
                <p class="mb-6" v-if="!addNewNumberData.IsPool">
                  <strong>Your configured phone number:</strong>
                </p>
                <p class="mb-6" v-if="addNewNumberData.IsPool">
                  <strong>Numbers selected for your pool:</strong>
                </p>
                <vs-radio
                  v-model="addNewNumberData.Number"
                  :vs-value="addNewNumberData.Number"
                  v-if="!addNewNumberData.IsPool"
                >{{addNewNumberData.Number}}</vs-radio>
                <ul class="number-list">
                  <li
                    v-for="(availablePhoneNumber, index) in addNewNumberData.Pool_Phone_Numbers"
                    :key="index"
                    v-if="addNewNumberData.IsPool"
                  >{{availablePhoneNumber}}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Step7: Recording and Transcription Options -->
        <div class="first-step-track" v-if="activeStep == 7">
          <h3>Recording and Transcription Options</h3>
          <div class="replace-source">
            <form data-vv-scope="stepSevenForm">
              <ul class="record-msg-list track-list">
                <li>
                  <vs-checkbox v-model="addNewNumberData.Record_Call" class="mb-4">Record Call</vs-checkbox>
                  <div class="record-msg pl-10" v-if="addNewNumberData.Record_Call">
                    <span class="input-label float-left pr-4 float-left w-full">Message</span>
                    <!-- <vs-input
                      class="float-left w-full"
                      v-model="addNewNumberData.Record_Call_Msg"
                      v-validate="addNewNumberData.Record_Call ? 'required' : '' "
                      name="recordCallMessage"
                    />
                    <span
                      class="text-danger text-sm error-msg"
                    >{{ errors.first('stepSevenForm.recordCallMessage') }}</span> -->
                    <vs-input
                      class="float-left w-full"
                      v-model="addNewNumberData.Record_Call_Msg"
                    />
                  </div>
                </li>
                <li>
                  <vs-checkbox v-model="addNewNumberData.Whisper" class="mb-4">Whisper Message</vs-checkbox>
                  <div class="record-msg pl-10" v-if="addNewNumberData.Whisper">
                    <span class="input-label float-left pr-4 float-left w-full">Message</span>
                    <vs-input
                      class="float-left w-full"
                      v-model="addNewNumberData.Whisper_Msg"
                      v-validate="addNewNumberData.Whisper ? 'required' : '' "
                      name="whisperMessage"
                    />
                    <span
                      class="text-danger text-sm error-msg"
                    >{{ errors.first('stepSevenForm.whisperMessage') }}</span>
                  </div>
                </li>
                <li>
                  <vs-checkbox v-model="addNewNumberData.IsTranscribe">Transcribe Call</vs-checkbox>
                </li>
              </ul>
            </form>
          </div>
        </div>
        <!-- Step8: Post Call Options -->
        <div class="first-step-track" v-if="activeStep == 8">
          <h3>Post Call Options</h3>
          <div class="replace-source post-option mt-8">
            <form data-vv-scope="stepEightForm">
              <ul class="track-list">
                <li>
                  <vs-checkbox
                    v-model="addNewNumberData.Send_Text_For_Missed_Call"
                    class="mb-6"
                  >Send Text Message to Caller on Missed Call</vs-checkbox>
                  <div class="vx-col w-full" v-if="addNewNumberData.Send_Text_For_Missed_Call">
                    <span class="input-label">Text Message</span>
                    <vs-textarea
                      class="mb-0"
                      v-model="addNewNumberData.Text_For_Missed_Call"
                      v-validate="'required'"
                      name="sendTextForMissedCall"
                    />
                    <span
                      class="text-danger text-sm error-msg w-full float-left"
                    >{{ errors.first('stepEightForm.sendTextForMissedCall') }}</span>
                  </div>
                </li>
                <li>
                  <vs-checkbox
                    v-model="addNewNumberData.Send_Email_On_Complete"
                    class="mb-6"
                  >Send Email on Completed Phone Call</vs-checkbox>
                  <div
                    class="vx-col w-full post-item"
                    v-if="addNewNumberData.Send_Email_On_Complete"
                  >
                    <div
                      class="post-all-list"
                      v-for="(emailOnComplete, index) in addNewNumberData.Email_On_Complete"
                      :key="index"
                    >
                      <span class="input-label">Email</span>
                      <vs-input
                        class="add-icon-input"
                        v-model="addNewNumberData.Email_On_Complete[index]"
                        v-validate="'required|email'"
                        :name="'email '+index"
                      />
                      <vs-button
                        color="success"
                        type="filled"
                        icon-pack="feather"
                        icon="icon-plus"
                        class="add-btn"
                        @click="addEmailOnComplete()"
                      ></vs-button>
                      <vs-button
                        v-if="index > 0"
                        color="danger"
                        type="filled"
                        icon-pack="feather"
                        icon="icon-minus"
                        class="add-btn"
                        @click="removeEmailOnComplete(index)"
                      ></vs-button>
                      <span
                        class="text-danger text-sm error-msg get-html-text-email-one"
                      >{{ errors.first('stepEightForm.email '+index) }}</span>
                    </div>
                  </div>
                </li>
                <li>
                  <vs-checkbox
                    v-model="addNewNumberData.Send_Text_On_Complete"
                    class="mb-6"
                  >Send Text Notification on Completed Phone Call</vs-checkbox>
                  <div
                    class="vx-col w-full post-item"
                    v-if="addNewNumberData.Send_Text_On_Complete"
                  >
                    <div
                      class="post-all-list"
                      v-for="(textOnCallComplete, index) in addNewNumberData.Text_On_Call_Complete"
                      :key="index"
                    >
                      <span class="input-label">Mobile</span>
                      <!-- <vs-input
                        class="add-icon-input"
                        v-model="addNewNumberData.Text_On_Call_Complete[index]"
                        v-validate="'required|digits:10'"
                        :name="'phone number '+index"
                      /> -->
                      <the-mask 
                        class="add-icon-input"
                        v-model="addNewNumberData.Text_On_Call_Complete[index]"
                        mask="###-###-####"
                        :masked='true'
                      />
                      <vs-button
                        color="success"
                        type="filled"
                        icon-pack="feather"
                        icon="icon-plus"
                        class="add-btn"
                        @click="addTextOnCallComplete()"
                      ></vs-button>
                      <vs-button
                        v-if="index > 0"
                        color="danger"
                        type="filled"
                        icon-pack="feather"
                        icon="icon-minus"
                        class="add-btn"
                        @click="removeTextOnCallComplete(index)"
                      ></vs-button>
                      <!-- <span
                        class="text-danger text-sm error-msg get-html-text-mobile-one"
                      >{{ errors.first('stepEightForm.phone number '+index) }}</span> -->
                    </div>
                  </div>
                </li>
                <li>
                  <vs-checkbox
                    v-model="addNewNumberData.Send_Text_To_Email"
                    class="mb-6"
                  >Forward Texts to Email (2way communication)</vs-checkbox>
                  <div class="vx-col w-full post-item" v-if="addNewNumberData.Send_Text_To_Email">
                    <div
                      class="post-all-list"
                      v-for="(textToEmailDestination, index) in addNewNumberData.Text_To_Email_Destination"
                      :key="index"
                    >
                      <span class="input-label">Email</span>
                      <vs-input
                        class="add-icon-input"
                        v-model="addNewNumberData.Text_To_Email_Destination[index]"
                        v-validate="'required|email'"
                        :name="'forward text email '+index"
                      />
                      <vs-button
                        color="success"
                        type="filled"
                        icon-pack="feather"
                        icon="icon-plus"
                        class="add-btn"
                        @click="addTextToEmailDestination()"
                      ></vs-button>
                      <vs-button
                        v-if="index > 0"
                        color="danger"
                        type="filled"
                        icon-pack="feather"
                        icon="icon-minus"
                        class="add-btn"
                        @click="removeTextToEmailDestination(index)"
                      ></vs-button>
                      <span
                        class="text-danger text-sm error-msg get-html-text-email-two"
                      >{{ errors.first('stepEightForm.forward text email '+index) }}</span>
                    </div>
                  </div>
                </li>
                <li>
                  <vs-checkbox
                    v-model="addNewNumberData.Send_Text_Notification_Of_Text"
                    class="mb-6"
                  >Send Text Notification on New Text</vs-checkbox>
                  <div
                    class="vx-col w-full post-item"
                    v-if="addNewNumberData.Send_Text_Notification_Of_Text"
                  >
                    <div
                      class="post-all-list"
                      v-for="(textForNewText, index) in addNewNumberData.Text_For_New_Text"
                      :key="index"
                    >
                      <span class="input-label">Mobile</span>
                      <!-- <vs-input
                        class="add-icon-input"
                        v-model="addNewNumberData.Text_For_New_Text[index]"
                        v-validate="'required|digits:10'"
                        :name="'send text notification phone number '+index"
                      /> -->
                      <the-mask 
                        class="add-icon-input"
                        v-model="addNewNumberData.Text_For_New_Text[index]"
                        mask="###-###-####"
                        :masked='true'
                      />
                      <vs-button
                        color="success"
                        type="filled"
                        icon-pack="feather"
                        icon="icon-plus"
                        class="add-btn"
                        @click="addTextForNewText()"
                      ></vs-button>
                      <vs-button
                        v-if="index > 0"
                        color="danger"
                        type="filled"
                        icon-pack="feather"
                        icon="icon-minus"
                        class="add-btn"
                        @click="removeTextForNewText(index)"
                      ></vs-button>
                      <!-- <span
                        class="text-danger text-sm error-msg get-html-text-mobile-two"
                      >{{ errors.first('stepEightForm.send text notification phone number '+index) }}</span> -->
                    </div>
                  </div>
                </li>
              </ul>
            </form>
          </div>
        </div>
        <!-- Step9: Review the information -->
        <div class="first-step-track" v-if="activeStep == 9">
          <p>Please review the information below, before proceeding with provisioning. Your account will be charged accordingly.</p>
          <vs-table max-items="8" :data="addNewNumberData">
            <template slot-scope="{data}">
              <!-- Step4- Campaign Name: -->
              <vs-tr>
                <vs-th>Campaign Name:</vs-th>
                <vs-td>{{data.Campaign_Name}}</vs-td>
              </vs-tr>
              <!-- Step6 -->
              <!-- Tracking Pool: -->
              <vs-tr v-if="data.IsPool">
                <vs-th>Tracking Pool</vs-th>
                <vs-td >
                  <span v-show="data.IsTollFree == 'true'"> Pool of {{data.Pool_Size}} numbers in area code:</span>
                  <span v-show="data.IsTollFree == 'false'"> Pool of {{data.Pool_Size}} numbers in area code: {{data.Area_Code}}</span>
                </vs-td>
              </vs-tr>
              <!-- Tracking Number: -->
              <vs-tr v-if="!data.IsPool">
                <vs-th>Tracking Number</vs-th>
                <vs-td>{{data.Number}}</vs-td>
              </vs-tr>
              <!-- Step2- Forward Call To: -->
              <vs-tr>
                <vs-th>Forward Call To</vs-th>
                <vs-td>{{data.Forward_To}}</vs-td>
              </vs-tr>
              <!-- Step1- Online Use: -->
              <vs-tr>
                <vs-th>Online Use</vs-th>
                <vs-td>
                  <span v-if="data.IsUsedOnline">Yes</span>
                  <span v-if="!data.IsUsedOnline">No</span>
                </vs-td>
              </vs-tr>
              <!-- Step2- Use DNI: -->
              <vs-tr>
                <vs-th>Use DNI</vs-th>
                <vs-td>
                  <span v-if="data.IsDNI">Yes</span>
                  <span v-if="!data.IsDNI">No</span>
                </vs-td>
              </vs-tr>
              <!-- Step4- Replace Number: -->
              <vs-tr v-if="data.IsDNI">
                <vs-th>Replace Number</vs-th>
                <vs-td>
                  <span
                    v-if="data.Replacement_Option == 'ReplaceOnlyDestination'"
                  >Replace only the destination number</span>
                  <span v-if="data.Replacement_Option == 'ReplaceAll'">Replace all phone numbers</span>
                  <span
                    v-if="data.Replacement_Option == 'ReplaceExact'"
                  >Replace this exact match: {{data.Replace_Exact_String}}</span>
                </vs-td>
              </vs-tr>
              <!-- Step5- Replace When: -->
              <!-- Case:1- For Pool -->
              <vs-tr v-if="data.IsDNI && data.IsPool">
                <vs-th>Replace When</vs-th>
                <vs-td
                  v-if="data.Pool_Track_Type == 'trackall'"
                >Always replace the number with a number from this pool</vs-td>
                <vs-td v-if="data.Pool_Track_Type == 'trackspecific'">
                  <span>Visitor is from the following search entities</span>
                  <ul>
                    <li v-if="data.Pool_Track_Google_PPC">Google PPC</li>
                    <li v-if="data.Pool_Track_Google_Organic">Google Organic</li>
                    <li v-if="data.Pool_Track_Google_Local">Google Local</li>
                    <li v-if="data.Pool_Track_Bing_PPC">Bing PPC</li>
                    <li v-if="data.Pool_Track_Bing_Organic">Bing Organic</li>
                    <li v-if="data.Pool_Track_Bing_Local">Bing Local</li>
                    <li v-if="data.Pool_Track_Yahoo_PPC">Yahoo PPC</li>
                    <li v-if="data.Pool_Track_Yahoo_Organic">Yahoo Organic</li>
                    <li v-if="data.Pool_Track_Yahoo_Local">Yahoo Local</li>
                  </ul>
                </vs-td>
              </vs-tr>
              <!-- Case:2- For Single -->
              <vs-tr v-if="data.IsDNI && !data.IsPool">
                <vs-th>Replace When</vs-th>
                <vs-td>
                  <span
                    v-if="data.Single_Track_Type == 'search' && data.Single_Track_Search == 'all search'"
                  >Visitor is from All Search, Organic and Paid</span>
                  <span
                    v-if="data.Single_Track_Type == 'search' && data.Single_Track_Search == 'paid search'"
                  >Visitor is from Paid Search, Google, Bing and Yahoo</span>
                  <span
                    v-if="data.Single_Track_Type == 'search' && data.Single_Track_Search == 'organic search'"
                  >Visitor is from Organic Search, all search engines</span>
                  <span
                    v-if="data.Single_Track_Type == 'search' && data.Single_Track_Search == 'google ppc'"
                  >Visitor is from Google PPC</span>
                  <span
                    v-if="data.Single_Track_Type == 'search' && data.Single_Track_Search == 'google organic'"
                  >Visitor is from Google Organic</span>
                  <span
                    v-if="data.Single_Track_Type == 'search' && data.Single_Track_Search == 'bing ppc'"
                  >Visitor is from Bing PPC</span>
                  <span
                    v-if="data.Single_Track_Type == 'search' && data.Single_Track_Search == 'bing organic'"
                  >Visitor is from Bing Organic</span>
                  <span
                    v-if="data.Single_Track_Type == 'search' && data.Single_Track_Search == 'yahoo ppc'"
                  >Visitor is from Yahoo PPC</span>
                  <span
                    v-if="data.Single_Track_Type == 'search' && data.Single_Track_Search == 'yahoo organic'"
                  >Visitor is from Yahoo Organic</span>

                  <span
                    v-if="data.Single_Track_Type == 'referral'"
                  >Visitor was referred from {{data.Single_Track_Referrer}}</span>
                  <span
                    v-if="data.Single_Track_Type == 'landingpage'"
                  >Visitor landed on {{data.Single_Track_Landing_Page}}</span>
                  <span
                    v-if="data.Single_Track_Type == 'landingpageparam'"
                  >Visitor's landing page had this parameter {{data.Single_Track_Landing_Page_Param}}</span>
                  <span v-if="data.Single_Track_Type == 'direct'">Visitor went directly to your site</span>
                  <span
                    v-if="data.Single_Track_Type == 'always'"
                  >Always replace the number with a number from this pool</span>
                </vs-td>
              </vs-tr>
              <!-- Step7 -->
              <!-- Record Call: -->
              <vs-tr>
                <vs-th>Record Call</vs-th>
                <vs-td>
                  <span v-if="data.Record_Call">Yes</span>
                  <span v-if="!data.Record_Call">No</span>
                </vs-td>
              </vs-tr>
              <!-- Record Call Message: -->
              <vs-tr v-if="data.Record_Call && data.Record_Call_Msg">
                <vs-th>Record Call Message</vs-th>
                <vs-td>{{data.Record_Call_Msg}}</vs-td>
              </vs-tr>
              <!-- Whisper Message: -->
              <vs-tr>
                <vs-th>Whisper Message</vs-th>
                <vs-td>
                  <span v-if="data.Whisper">Yes</span>
                  <span v-if="!data.Whisper">No</span>
                </vs-td>
              </vs-tr>
              <!-- Message: -->
              <vs-tr v-if="data.Whisper && data.Whisper_Msg">
                <vs-th>Message</vs-th>
                <vs-td>{{data.Whisper_Msg}}</vs-td>
              </vs-tr>
              <!-- Transcribe Message: -->
              <vs-tr>
                <vs-th>Transcribe Message:</vs-th>
                <vs-td>
                  <span v-if="data.IsTranscribe">Yes</span>
                  <span v-if="!data.IsTranscribe">No</span>
                </vs-td>
              </vs-tr>
              <!-- Step8 -->
              <!-- Missed Call Text: -->
              <vs-tr>
                <vs-th>Missed Call Text</vs-th>
                <vs-td>
                  <span v-if="data.Send_Text_For_Missed_Call">Yes</span>
                  <span v-if="!data.Send_Text_For_Missed_Call">No</span>
                </vs-td>
              </vs-tr>
              <!-- Text Message: -->
              <vs-tr v-if="data.Send_Text_For_Missed_Call">
                <vs-th>Text Message</vs-th>
                <vs-td>{{data.Text_For_Missed_Call}}</vs-td>
              </vs-tr>
              <!-- Email Call: -->
              <vs-tr>
                <vs-th>Email Call:</vs-th>
                <vs-td>
                  <span v-if="data.Send_Email_On_Complete">Yes</span>
                  <span v-if="!data.Send_Email_On_Complete">No</span>
                </vs-td>
              </vs-tr>
              <!-- Email To: -->
              <vs-tr v-if="data.Send_Email_On_Complete">
                <vs-th>Email To:</vs-th>
                <vs-td>
                  <p
                    v-for="(emailOnComplete, index) in data.Email_On_Complete"
                    :key="index"
                  >{{emailOnComplete}}</p>
                </vs-td>
              </vs-tr>
              <!-- Text For New Call: -->
              <vs-tr>
                <vs-th>Text For New Call:</vs-th>
                <vs-td>
                  <span v-if="data.Send_Text_On_Complete">Yes</span>
                  <span v-if="!data.Send_Text_On_Complete">No</span>
                </vs-td>
              </vs-tr>
              <!-- Text To: -->
              <vs-tr v-if="data.Send_Text_On_Complete">
                <vs-th>Text To:</vs-th>
                <vs-td>
                  <p
                    v-for="(textOnCallComplete, index) in data.Text_On_Call_Complete"
                    :key="index"
                  >{{textOnCallComplete}}</p>
                </vs-td>
              </vs-tr>
              <!-- Text to Email: -->
              <vs-tr>
                <vs-th>Text to Email:</vs-th>
                <vs-td>
                  <span v-if="data.Send_Text_To_Email">Yes</span>
                  <span v-if="!data.Send_Text_To_Email">No</span>
                </vs-td>
              </vs-tr>
              <!-- Email To: -->
              <vs-tr v-if="data.Send_Text_To_Email">
                <vs-th>Email To:</vs-th>
                <vs-td>
                  <p
                    v-for="(textToEmailDestination, index) in data.Text_To_Email_Destination"
                    :key="index"
                  >{{textToEmailDestination}}</p>
                </vs-td>
              </vs-tr>
              <!-- Text for New Text: -->
              <vs-tr>
                <vs-th>Text for New Text:</vs-th>
                <vs-td>
                  <span v-if="data.Send_Text_Notification_Of_Text">Yes</span>
                  <span v-if="!data.Send_Text_Notification_Of_Text">No</span>
                </vs-td>
              </vs-tr>
              <!-- Text To: -->
              <vs-tr v-if="data.Send_Text_Notification_Of_Text">
                <vs-th>Text To:</vs-th>
                <vs-td>
                  <p
                    v-for="(textForNewText, index) in data.Text_For_New_Text"
                    :key="index"
                  >{{textForNewText}}</p>
                </vs-td>
              </vs-tr>
              <!-- Active: -->
              <vs-tr v-if="data.IsUsedOnline && editMode">
                <vs-th>Active:</vs-th>
                <vs-td>
                  <span v-if="data.IsActive">Yes</span>
                  <span v-if="!data.IsActive">No</span>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
        <!-- Step10: Submission process -->
        <div class="first-step-track" v-if="activeStep == 10">
          <p>Please wait...</p>
        </div>
      </div>
      <!-- Back, Nextuttons -->
      <div class="flex justify-between modal-action-buttons mt-10 w-full float-left flex-wrap mb-8">
        <div class="sm:w-auto w-full">
          <vs-button
            class="sm:w-auto w-full mb-2 sm:mb-0"          
            type="filled"
            v-if="activeStep != 1 && !editMode"
            @click="backStep()"
            color="primary"          
          >Back</vs-button>
          <vs-button
            class="sm:w-auto w-full mb-2 sm:mb-0"            
            type="filled"
            v-if="activeStep != 4 && editMode"
            @click="backStep()"   
            color="primary"         
          >Back</vs-button>
        </div>
        <div class="sm:w-auto w-full">
          <vs-button
            class="mr-0 sm:mr-4 sm:w-auto w-full mb-2 sm:mb-0"            
            type="filled"
            @click="nextStep(activeStep + 1)"
            v-if="!isShowNextButton && addNewNumberData.IsDNI"
            color="primary"
          >{{nextButtonLabel}}</vs-button>
          <vs-button
            class="mr-0 sm:mr-4 sm:w-auto w-full mb-2 sm:mb-0"            
            type="filled"
            @click="nextStep(activeStep + 2)"
            v-if="!isShowNextButton && !addNewNumberData.IsDNI && activeStep == 4"
            color="primary"
          >{{nextButtonLabel}}</vs-button>
          <vs-button
            class="mr-0 sm:mr-4 sm:w-auto w-full mb-2 sm:mb-0"            
            type="filled"
            @click="nextStep(activeStep + 1)"
            v-if="!isShowNextButton && !addNewNumberData.IsDNI && activeStep != 4"
            color="primary"
          >{{nextButtonLabel}}</vs-button>
          <!-- For step6 -->
          <vs-button
            class="mr-0 sm:mr-4 sm:w-auto w-full mb-2 sm:mb-0"            
            type="filled"
            @click="nextStep(activeStep + 1)"
            v-if="activeStep == 6 && !addNewNumberData.IsPool"
            :disabled="addNewNumberData.Number == ''"
            color="primary"
          >{{nextButtonLabel}}</vs-button>
          <vs-button
            class="mr-0 sm:mr-4 sm:w-auto w-full mb-2 sm:mb-0"            
            type="filled"
            @click="nextStep(activeStep + 1)"
            v-if="activeStep == 6 && addNewNumberData.IsPool && !editMode"
            :disabled="availablePhoneNumbers.length < 3"
            color="primary"
          >{{nextButtonLabel}}</vs-button>
          <vs-button
            class="mr-0 sm:mr-4 sm:w-auto w-full mb-2 sm:mb-0"            
            type="filled"
            @click="nextStep(activeStep + 1)"
            v-if="activeStep == 6 && addNewNumberData.IsPool && editMode"
            color="primary"
          >{{nextButtonLabel}}</vs-button>
          <vs-button
            @click="closeAddNewNumberTracking()"
            color="grey"
            type="border"
            class="sm:w-auto w-full grey-btn"
          >{{ LabelConstant.buttonLabelClose }}</vs-button>
        </div>
      </div>
    </vs-popup>
    <!-- Call Traking Rate -->
    <vs-popup
      class="add-new"
      title="Call Tracking Service Pricing"
      :active.sync="calltrackingActive"
      >
      <template>
        <div class="tracking-service">
          <div class="vx-row">
            <div class="vx-col md:w-1/2 mb-6 md:mb-0 w-full">
              <div class="tracking-info">
                <h4>Included Usage</h4>
                <ul>
                  <li>5 Local Numbers</li>
                  <li>100 Local Minutes</li>
                  <li>50 Text Messages</li>
                </ul>
              </div>
            </div>
            <div class="vx-col md:w-1/2 w-full">
              <div class="tracking-info">
                <h4>Additional Usage</h4>
                <ul>
                  <li>
                    <span>Each Additional Number:</span>$2 local or $5 toll free
                  </li>
                  <li>
                    <span>Additional Minutes:</span>4¢ local or 6¢ toll free
                  </li>
                  <li>
                    <span>Additional Text Messages:</span>1.5¢ per message
                  </li>
                  <li>
                    <span>Call Transcription:</span>5¢ per minute
                  </li>
                  <li>
                    <span>Call Recording:</span>Included
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button
          @click="calltrackingActive = false"
          color="grey"
          type="border"
          class="mr-2 grey-btn"
        >{{ LabelConstant.buttonLabelClose }}</vs-button>
      </div>
    </vs-popup>
  </div>
</template>
<script>
// import Vue from "vue";
import VxCard from "@/components/vx-card/VxCard";
import HelpModal from "../../components/modal/HelpModal";
import vSelect from "vue-select";
import { Validator } from "vee-validate";
import {TheMask} from 'vue-the-mask'
/* export excel */
import JsonExcel from "vue-json-excel";

const dict = {
  custom: {
    campaignName: {
      required: "A campaign name is required."
    },
    // forwardTo: {
    //   required: "A forward to number is required.",
    //   digits: "Please enter valid phone number."
    // },
    // extraForwardTo: {
    //     required: "A forward to number is required.",
    //     digits: "Please enter valid phone number."
    // }
    replaceExactString: {
      required: "Please enter a string to replace."
    },
    areaCode: {
      required: "Please enter a valid area code."
    },
    recordCallMessage: {
      required: "Please enter a record call message."
    },
    whisperMessage: {
      required: "Please enter a whisper message."
    },
    sendTextForMissedCall: {
      required: "Please enter a text message."
    }
  }
};

Validator.localize("en", dict);

export default {
  components: {
    VxCard,
    HelpModal,
    vSelect,
    downloadExcel: JsonExcel,
    TheMask
  },
  data() {
    return {
      isFreeTrial: null,

      isAddNewPhoneNumber: false,
      /* Help Popup */
      popupActive: false,

      //View call tracking rates popup
      calltrackingActive: false,

      //Not used below properties
      //localNumbers: 0,
      //minutesOfUse: 0,

      //Phone Numbers Configured list
      provisionedPhoneNumbers: [],
      perPage: 5,
      recordList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 }
      ],

      //Delete traking Phone number popup
      deletepopupActive: false,
      deletableTrakingPhoneNumberId: null,
      deletableTrakingPhoneNumber: null,

      //Add New Phone Number popup
      addphoneActive: false,
      addOrEditTrackingPhoneNumberTitle: null,

      //Add New Phone Number wizard step form configuration data
      progressBar: 10,
      equalProgressDivision: 10,
      activeStep: 1,
      completedStepArr: [],
      nextButtonLabel: "Next",

      //Add New Phone Number data
      addNewNumberDataArea_Code_New: null,
      addNewNumberData: {
        Id: "",
        Number: "",
        National_Number: "",
        Campaign_Name: "",
        Forward_To: "",
        Record_Call: false,
        Record_Call_Msg: "",
        Whisper: false,
        Whisper_Msg: "",
        IsDNI: false,
        IsPool: false,
        IsUsedOnline: false,
        IsTranscribe: false,
        Replacement_Option: "ReplaceAll",
        Replace_Exact_String: "",
        Replacement_Format: "6",
        Pool_Track_Type: "trackall",
        Pool_Track_Google_PPC: false,
        Pool_Track_Google_Organic: false,
        Pool_Track_Google_Local: false,
        Pool_Track_Bing_PPC: false,
        Pool_Track_Bing_Organic: false,
        Pool_Track_Bing_Local: false,
        Pool_Track_Yahoo_PPC: false,
        Pool_Track_Yahoo_Organic: false,
        Pool_Track_Yahoo_Local: false,
        Single_Track_Type: "search",
        Single_Track_Search: "all search",
        Single_Track_Referrer: "",
        Single_Track_Landing_Page: "",
        Single_Track_Landing_Page_Param: "",
        Pool_Size: 6,
        Area_Code: "678",
        Area_Code_800: "844",
        IsTollFree: "false",
        Pool_Phone_Numbers: [],
        Extra_Forward_To: [],
        Send_Email_On_Complete: false,
        Send_Text_To_Email: false,
        Send_Text_Notification_Of_Text: false,
        Send_Text_For_Missed_Call: false,
        Send_Text_On_Complete: false,
        Text_For_Missed_Call: "",
        Text_To_Email_Destination: [""],
        Text_For_New_Text: [""],
        Text_On_Call_Complete: [""],
        Email_On_Complete: [""],
        IsActive: false
      },

      //Step4
      replacementShow: false,
      replacementOptions: [
        { id: "6", label: "(999) 999-9999" },
        { id: "7", label: "999-999-9999" },
        { id: "8", label: "999.999.9999" },
        { id: "9", label: "999 999 9999" }
      ],

      //Step5
      stepFiveErrorMessage: null,
      searchEngineOptions: [
        { id: "all search", label: "All Search, Organic and Paid" },
        { id: "paid search", label: "Paid Search, Google, Bing and Yahoo" },
        { id: "organic search", label: "Organic Search, all search engines" },
        { id: "google ppc", label: "Google PPC" },
        { id: "google organic", label: "Google Organic" },
        { id: "bing ppc", label: "Bing PPC" },
        { id: "bing organic", label: "Bing Organic" },
        { id: "yahoo ppc", label: "Yahoo PPC" },
        { id: "yahoo organic", label: "Yahoo Organic" }
      ],

      //Step6
      poolSizeOptions: [
        { id: "6", label: "6" },
        { id: "7", label: "7" },
        { id: "8", label: "8" },
        { id: "9", label: "9" },
        { id: "10", label: "10" },
        { id: "11", label: "11" },
        { id: "12", label: "12" },
        { id: "13", label: "13" },
        { id: "14", label: "14" },
        { id: "15", label: "15" },
        { id: "16", label: "16" },
        { id: "17", label: "17" },
        { id: "18", label: "18" },
        { id: "19", label: "19" },
        { id: "20", label: "20" },
        { id: "25", label: "25" },
        { id: "30", label: "30" },
        { id: "35", label: "35" },
        { id: "40", label: "40" },
        { id: "45", label: "45" },
        { id: "50", label: "50" },
        { id: "55", label: "55" },
        { id: "60", label: "60" },
        { id: "65", label: "65" },
        { id: "70", label: "70" },
        { id: "75", label: "75" },
        { id: "80", label: "80" }
      ],
      tollFreeOptions: [{ id: "844", label: "Toll Free" }],
      availablePhoneNumbers: [],
      stepSixErrorMessage: null,

      //Edit New Phone Number data
      editMode: false,
      excelFields: {
        "Campaign Name": "CampaignName",
        "Tracking Number": "TrackingNumber",
        "Forward Call To": "ForwardCallTo",
        Status: "Status",
        "Record Call": "RecordCall",
        "Date Created": "DateCreated"
      },
      excelData: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8"
          }
        ]
      ]
    };
  },
  mounted() {
    let that = this;
    //Filter Location Breakdown datatable record based on search parameter for excel
    setTimeout(() => {
      document
        .querySelector("#callTrackingNumbers .input-search")
        .addEventListener("keyup", function() {
          that.excelData = that.filterExport(true);
        });
    }, 1000);
  },
  created() {
    let uid = this.$route.params.id
    let VVtoken = localStorage.getItem("VVtoken");
    let token = JSON.parse(VVtoken);
    this.isFreeTrial = token[uid].isActiveFreeTrial
    this.listProvisionedPhoneNumbers();
    //Not used
    // this.getProvisionedPhoneNumberCountForFreeTrial();
  },
  methods: {
    /* Close Popup */
    closepopup() {
      this.popupActive = false;
      this.detailspopupActive = false;
    },
    //Cancel Traking Phone Number Deletion
    cancelTrakingPhoneNumberDeletion() {
      this.deletableTrakingPhoneNumberId = null;
      this.deletableTrakingPhoneNumber = null;
      this.deletepopupActive = false;
    },
    //Add New Phone Number back functionality
    backStep() {
      this.activeStep = this.completedStepArr.pop();
      this.progressBar = this.activeStep * this.equalProgressDivision;
      this.nextButtonLabel = this.changeNextButtonLabel();
    },
    //Add New Phone Number next functionality
    nextStep(nextStep) {
      if (this.activeStep == 4) {
        if (this.addNewNumberData.Replacement_Option != "ReplaceExact") {
          this.$validator.validateAll("fourthStepForm").then(result => {
            if (result) {
              // this.moveNextStep(nextStep);
              this.addNewNumberData.IsDNI
                ? this.numberWizardStep4(nextStep)
                : this.numberWizardStep5(nextStep);
            }
          });
        }
        if (this.addNewNumberData.Replacement_Option == "ReplaceExact") {
          this.$validator.validateAll("fourthStepForm").then(result => {
            if (result) {
              if (this.addNewNumberData.Replacement_Option == "ReplaceExact") {
                this.$validator
                  .validateAll("fourthStepFormTwo")
                  .then(result => {
                    if (result) {
                      // this.moveNextStep(nextStep);
                      this.addNewNumberData.IsDNI
                        ? this.numberWizardStep4(nextStep)
                        : this.numberWizardStep5(nextStep);
                    }
                  });
              }
            }
          });
        }
      } else if (this.activeStep == 5) {
        if (this.addNewNumberData.IsPool) {
          if (this.addNewNumberData.Pool_Track_Type == "trackspecific") {
            if (
              this.addNewNumberData.Pool_Track_Google_PPC == false &&
              this.addNewNumberData.Pool_Track_Google_Organic == false &&
              this.addNewNumberData.Pool_Track_Google_Local == false &&
              this.addNewNumberData.Pool_Track_Bing_PPC == false &&
              this.addNewNumberData.Pool_Track_Bing_Organic == false &&
              this.addNewNumberData.Pool_Track_Bing_Local == false &&
              this.addNewNumberData.Pool_Track_Yahoo_PPC == false &&
              this.addNewNumberData.Pool_Track_Yahoo_Organic == false &&
              this.addNewNumberData.Pool_Track_Yahoo_Local == false
            ) {
              this.stepFiveErrorMessage = "Please select a source to track.";
            } else {
              this.stepFiveErrorMessage = null;
              // this.moveNextStep(nextStep);
              this.numberWizardStep5(nextStep);
            }
          } else {
            this.stepFiveErrorMessage = null;
            // this.moveNextStep(nextStep);
            this.numberWizardStep5(nextStep);
          }
        } else {
          if (
            this.addNewNumberData.Single_Track_Type == "referral" &&
            this.addNewNumberData.Single_Track_Referrer == ""
          ) {
            this.stepFiveErrorMessage = "Please enter a referral source.";
          } else if (
            this.addNewNumberData.Single_Track_Type == "landingpage" &&
            this.addNewNumberData.Single_Track_Landing_Page == ""
          ) {
            this.stepFiveErrorMessage = "Please enter a landing page.";
          } else if (
            this.addNewNumberData.Single_Track_Type == "landingpageparam" &&
            this.addNewNumberData.Single_Track_Landing_Page_Param == ""
          ) {
            this.stepFiveErrorMessage =
              "Please enter a landing page parameter.";
          } else {
            this.stepFiveErrorMessage = null;
            // this.moveNextStep(nextStep);
            this.numberWizardStep5(nextStep);
          }
        }
      } else if (this.activeStep == 7) {
        this.$validator.validateAll("stepSevenForm").then(result => {
          if (result) {
            this.moveNextStep(nextStep);
            this.numberWizardStep7();
          }
        });
      } else if (this.activeStep == 8) {
        this.$validator.validateAll("stepEightForm").then(result => {
          let getHtmlTextEmailOneErrorArr = document.getElementsByClassName("get-html-text-email-one")
          for (var i = 0; i < getHtmlTextEmailOneErrorArr.length; i++) {
            if(getHtmlTextEmailOneErrorArr[i].innerText.length > 0){
              document.getElementsByClassName("get-html-text-email-one")[i].innerText = getHtmlTextEmailOneErrorArr[i].innerText.replace(i+" ", "")
            }
          }
          let getHtmlTextEmailTwoErrorArr = document.getElementsByClassName("get-html-text-email-two")
          for (var j = 0; j < getHtmlTextEmailTwoErrorArr.length; j++) {
            if(getHtmlTextEmailTwoErrorArr[j].innerText.length > 0){
              document.getElementsByClassName("get-html-text-email-two")[j].innerText = getHtmlTextEmailTwoErrorArr[j].innerText.replace(j+" ", "")
            }
          }
          let getHtmlTextMobileOneErrorArr = document.getElementsByClassName("get-html-text-mobile-one")
          for (var k = 0; k < getHtmlTextMobileOneErrorArr.length; k++) {
            if(getHtmlTextMobileOneErrorArr[k].innerText.length > 0){
              document.getElementsByClassName("get-html-text-mobile-one")[k].innerText = getHtmlTextMobileOneErrorArr[k].innerText.replace(k+" ", "")
            }
          }
          let getHtmlTextMobileTwoErrorArr = document.getElementsByClassName("get-html-text-mobile-two")
          for (var l = 0; l < getHtmlTextMobileTwoErrorArr.length; l++) {
            if(getHtmlTextMobileTwoErrorArr[l].innerText.length > 0){
              document.getElementsByClassName("get-html-text-mobile-two")[l].innerText = getHtmlTextMobileTwoErrorArr[l].innerText.replace(l+" ", "")
            }
          }
          if (result) {
            // this.moveNextStep(nextStep);
            this.numberWizardStep8(nextStep);
          }
        });
      } else if (this.activeStep == 9) {
        this.moveNextStep(nextStep);
        this.numberWizardStep9();
      } else {
        this.moveNextStep(nextStep);
      }
    },
    moveNextStep(nextStep) {
      this.completedStepArr.push(this.activeStep);
      this.activeStep = nextStep;
      this.progressBar = this.activeStep * this.equalProgressDivision;
      this.nextButtonLabel = this.changeNextButtonLabel();
    },
    //Change 'Next' button label on step change
    changeNextButtonLabel() {
      return this.activeStep == 4 && this.addNewNumberData.IsDNI
        ? "Next - Configure Tracking"
        : this.activeStep == 4 && !this.addNewNumberData.IsDNI
        ? "Next - Find Numbers"
        : this.activeStep == 5
        ? "Next - Find Numbers"
        : this.activeStep == 6
        ? "Next - Options"
        : this.activeStep == 7
        ? "Next - Post Call Options"
        : this.activeStep == 8
        ? "Next - Review"
        : this.activeStep == 9
        ? "Finish - Provision"
        : "Next";
    },
    //Step4 - add extra forward to phone number
    addExtraForwardToPhoneNumber() {
      this.addNewNumberData.Extra_Forward_To.push("");
    },
    //Step4 - remove extra forward to phone number
    removeExtraForwardToPhoneNumber(index) {
      this.addNewNumberData.Extra_Forward_To.splice(index, 1);
    },
    //Step8 - add extra Send Email on Completed Phone Call field
    addEmailOnComplete() {
      this.addNewNumberData.Email_On_Complete.push("");
    },
    //Step8 - remove extra Send Email on Completed Phone Call field
    removeEmailOnComplete(index) {
      this.addNewNumberData.Email_On_Complete.splice(index, 1);
    },
    //Step8 - add extra Send Text Notification on Completed Phone Call field
    addTextOnCallComplete() {
      this.addNewNumberData.Text_On_Call_Complete.push("");
    },
    //Step8 - remove extra Send Text Notification on Completed Phone Call field
    removeTextOnCallComplete(index) {
      this.addNewNumberData.Text_On_Call_Complete.splice(index, 1);
    },
    //Step8 - add extra Forward Texts to Email (2way communication) field
    addTextToEmailDestination() {
      this.addNewNumberData.Text_To_Email_Destination.push("");
    },
    //Step8 - remove extra Forward Texts to Email (2way communication) field
    removeTextToEmailDestination(index) {
      this.addNewNumberData.Text_To_Email_Destination.splice(index, 1);
    },
    //Step8 - add extra Send Text Notification on New Text field
    addTextForNewText() {
      this.addNewNumberData.Text_For_New_Text.push("");
    },
    //Step8 - remove extra Send Text Notification on New Text field
    removeTextForNewText(index) {
      this.addNewNumberData.Text_For_New_Text.splice(index, 1);
    },
    //Close the Add New Tracking Number popup and clear data
    closeAddNewNumberTracking() {
      this.addphoneActive = false;
      this.addOrEditTrackingPhoneNumberTitle = null;
      this.editMode = false;

      this.progressBar = 10;
      this.activeStep = 1;
      this.completedStepArr = [];
      this.nextButtonLabel = "Next";

      this.replacementShow = false;

      this.stepFiveErrorMessage = null;

      this.availablePhoneNumbers = [];
      this.stepSixErrorMessage = null;

      setTimeout(() => {
        this.errors.clear("fourthStepForm");
        this.errors.clear("fourthStepFormTwo");
        this.errors.clear("stepSevenForm");
        this.errors.clear("stepEightForm");
      }, 20);
      setTimeout(() => {
        /*
                this.addNewNumberData = {
                    Id: "",
                    Number: "",
                    National_Number: "",
                    Campaign_Name: "",
                    Forward_To: "",
                    Record_Call: false,
                    Record_Call_Msg: "",
                    Whisper: false,
                    Whisper_Msg: "",
                    IsDNI: false,
                    IsPool: false,
                    IsUsedOnline: false,
                    IsTranscribe: false,
                    Replacement_Option: "ReplaceAll",
                    Replace_Exact_String: "",
                    Replacement_Format: "6",
                    Pool_Track_Type: "trackall",
                    Pool_Track_Google_PPC: false,
                    Pool_Track_Google_Organic: false,
                    Pool_Track_Google_Local: false,
                    Pool_Track_Bing_PPC: false,
                    Pool_Track_Bing_Organic: false,
                    Pool_Track_Bing_Local: false,
                    Pool_Track_Yahoo_PPC: false,
                    Pool_Track_Yahoo_Organic: false,
                    Pool_Track_Yahoo_Local: false,
                    Single_Track_Type: "search",
                    Single_Track_Search: "all search",
                    Single_Track_Referrer: "",
                    Single_Track_Landing_Page: "",
                    Single_Track_Landing_Page_Param: "",
                    Pool_Size: 6,
                    Area_Code: "678",
                    Area_Code_800: "844",
                    IsTollFree: "false",
                    Pool_Phone_Numbers: [],
                    Extra_Forward_To: [],
                    Send_Email_On_Complete: false,
                    Send_Text_To_Email: false,
                    Send_Text_Notification_Of_Text: false,
                    Send_Text_For_Missed_Call: false,
                    Send_Text_On_Complete: false,
                    Text_For_Missed_Call: "",
                    Text_To_Email_Destination: [""],
                    Text_For_New_Text: [""],
                    Text_On_Call_Complete: [""],
                    Email_On_Complete: [""],
                    IsActive: false
                }
                */
        Object.assign(
          this.$data.addNewNumberData,
          this.$options.data().addNewNumberData
        );
        this.addNewNumberDataArea_Code_New = null
      }, 20);
    },
    //Phone Numbers Configured
    listProvisionedPhoneNumbers() {
      this.$vs.loading();
      this.axios
        .get("/ws/CallTrackNumber/ListProvisionedPhoneNumbers")
        .then(response => {
          let data = response.data;
          let uid = this.$route.params.id
          let VVtoken = localStorage.getItem("VVtoken");
          let token = JSON.parse(VVtoken);
          this.isAddNewPhoneNumber = data.length < token[uid].maxLocalNumbers ? false : true

          this.provisionedPhoneNumbers = data;

          this.provisionedPhoneNumbers.sort(
            this.GetSortOrder("Campaign_Name", "ASC")
          ); //Pass the attribute to be sorted on
          this.provisionedPhoneNumbers.map(function(manipulatedData) {
            manipulatedData.sortableDate = new Date(
              manipulatedData.Date_Created
            );
            return manipulatedData;
          });
          this.excelData = this.filterExport();
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },   
    //Get Traking phone number details for deletion
    getIndividualCallTrackerForDelete(id) {
      this.$vs.loading();
      this.axios
        .get("/ws/CallTrackNumber/GetIndividualCallTracker?id=" + id)
        .then(response => {
          let data = response.data;
          this.deletableTrakingPhoneNumberId = id;
          this.deletableTrakingPhoneNumber = data.Number;
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //Delete Traking phone number record
    deleteTrackingNumber() {
      this.$vs.loading();
      this.axios
        .get(
          "/ws/CallTrackNumber/DeleteTrackingNumber?id=" +
            this.deletableTrakingPhoneNumberId
        )
        .then(() => {
          this.cancelTrakingPhoneNumberDeletion();
          this.listProvisionedPhoneNumbers();
          this.$vs.notify({
            title: "Success",
            text: "Tracking number delete successful.",
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000
          });
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000
          });
        });
    },
    //Add New Phone Number: numberWizardStep4
    numberWizardStep4(nextStep) {
      this.$vs.loading();
      this.axios
        .post("/ws/CallTrackNumber/NumberWizardStep4", this.addNewNumberData)
        .then(() => {
          this.moveNextStep(nextStep)
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000
          });
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //Add New Phone Number: numberWizardStep5
    numberWizardStep5(nextStep) {
      this.$vs.loading();
      this.axios
        .post("/ws/CallTrackNumber/NumberWizardStep5", this.addNewNumberData)
        .then(() => {
          this.moveNextStep(nextStep)
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000
          });
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //Get available phone number
    findAvailablePhoneNumbers() {
      let isTollFree = this.addNewNumberData.IsTollFree == "true" ? true : false;
      let areaCode = isTollFree ? this.addNewNumberData.Area_Code_800 : this.addNewNumberDataArea_Code_New
      let findAvailablePhoneNumbersParams = {
        Area_Code: areaCode,
        Requested_Count: this.addNewNumberData.Pool_Size,
        IsTollFree: isTollFree
      };
      this.stepSixErrorMessage = null;

      this.$vs.loading();
      this.axios
        .post(
          "/ws/CallTrackNumber/FindAvailablePhoneNumbers",
          findAvailablePhoneNumbersParams
        )
        .then(response => {
          let data = response.data;
          this.availablePhoneNumbers = data;
          this.$vs.loading.close();
        })
        .catch(e => {
          if(!e.response.data.Errors[0].Message){
            this.stepSixErrorMessage = "Please enter a valid area code.";
          }else{
            this.stepSixErrorMessage = e.response.data.Errors[0].Message;
          }
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //Add New Phone Number: numberWizardStep7
    numberWizardStep7() {
      if(this.addNewNumberDataArea_Code_New){
        this.addNewNumberData.Area_Code = this.addNewNumberDataArea_Code_New
      }
      this.$vs.loading();
      this.axios
        .post("/ws/CallTrackNumber/NumberWizardStep7", this.addNewNumberData)
        .then(() => {
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //Add New Phone Number: numberWizardStep8
    numberWizardStep8(nextStep) {
      if(this.addNewNumberDataArea_Code_New){
        this.addNewNumberData.Area_Code = this.addNewNumberDataArea_Code_New
      }
      this.$vs.loading();
      this.axios
        .post("/ws/CallTrackNumber/NumberWizardStep8", this.addNewNumberData)
        .then(() => {
          this.moveNextStep(nextStep)
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000
          });
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    //Add New Phone Number: numberWizardStep9
    numberWizardStep9() {
      if(this.addNewNumberDataArea_Code_New){
        this.addNewNumberData.Area_Code = this.addNewNumberDataArea_Code_New
      }
      this.$vs.loading();
      this.axios
        .post("/ws/CallTrackNumber/NumberWizardStep9", this.addNewNumberData)
        .then(() => {
          this.closeAddNewNumberTracking();
          this.listProvisionedPhoneNumbers();
          this.$vs.notify({
            title: "Success",
            text: "Call Tracking Number Created successful.",
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right",
            time: 4000
          });
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000
          });
        });
    },
    //Get data individual call tracker for Edit
    getIndividualCallTrackerForEdit(id) {
      this.$vs.loading();
      this.axios
        .get("/ws/CallTrackNumber/GetIndividualCallTracker?id=" + id)
        .then(response => {
          let data = response.data;
          this.addNewNumberData = data;
          this.editMode = true;
          this.progressBar = 40;
          this.activeStep = 4;
          this.completedStepArr = [4];
          this.nextButtonLabel = data.IsDNI ? "Next - Configure Tracking" : "Next - Find Numbers";
          this.addNewNumberData.IsTollFree = data.IsTollFree ? "true" : "false";
          this.addNewNumberData.Area_Code = data.Pool_Phone_Numbers[0].split("-")[0];
          if (data.Text_To_Email_Destination.length == 0) {
            this.addNewNumberData.Text_To_Email_Destination.push("");
          }
          if (data.Text_For_New_Text.length == 0) {
            this.addNewNumberData.Text_For_New_Text.push("");
          }
          if (data.Text_On_Call_Complete.length == 0) {
            this.addNewNumberData.Text_On_Call_Complete.push("");
          }
          if (data.Email_On_Complete.length == 0) {
            this.addNewNumberData.Email_On_Complete.push("");
          }
          this.addphoneActive = true;

          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    filterExport(search = false) {
      let data = [];
      let localListingData = !search
        ? this.provisionedPhoneNumbers
        : this.$refs.tableData.datax;

      for (var i = 0; i < localListingData.length; i++) {
        data.push({
          CampaignName: localListingData[i].Campaign_Name,
          TrackingNumber: localListingData[i].Number,
          ForwardCallTo: localListingData[i].Forward_To,
          Status: (localListingData[i].IsActive)?"Active":"Disabled",
          RecordCall: (localListingData[i].Record_Call)?"Recorded":"Not Recorded",
          DateCreated: localListingData[i].Date_Created
        });
      }
      return data;
    }
  },
  computed: {
    isShowNextButton: function() {
      let exceptionStep = [1, 2, 3, 6];
      return exceptionStep.includes(this.activeStep);
    },
    /**
		 * Showing Entries details
		 * isTableRecordEntriesDisplay()
		 * isSearch()
		 * showingDataFrom()
		 * showingDataTo()
		 * totalSearchedData()
     * showingSearchedDataTo()
		 *  */
    isTableRecordEntriesDisplay(){
			if(this.provisionedPhoneNumbers.length > 0){
				if(this.$refs.tableData.datax.length > 0){
					return true
				}else{
					return false
				}
			}else{
				return false
			}
		},
    isSearch: function () {
      if(this.provisionedPhoneNumbers.length > 0){
        if(this.$refs.tableData.searchx == null || this.$refs.tableData.searchx == ""){
            return false
        }else{
            return true
        }
      }
    },
    showingDataFrom: function () {
      if(this.provisionedPhoneNumbers.length > 0){
        return ((this.$refs.tableData.currentx - 1) * this.perPage) + 1 
      }
    },
    showingDataTo: function () {
      if(this.provisionedPhoneNumbers.length > 0){
        let totalPages = this.$refs.tableData.getTotalPages
        if(totalPages == this.$refs.tableData.currentx){
            return ((this.$refs.tableData.currentx - 1) * this.perPage) + this.$refs.tableData.datax.length
        }else{
            return ((this.$refs.tableData.currentx) * this.perPage)
        }
      }
    },
    totalSearchedData: function () {
      if(this.provisionedPhoneNumbers.length > 0){
        return this.$refs.tableData.queriedResults.length
      }
    },
    showingSearchedDataTo: function () {
      if(this.provisionedPhoneNumbers.length > 0){
        let totalPages = this.$refs.tableData.getTotalPagesSearch
        if(totalPages == this.$refs.tableData.currentx){
            return ((this.$refs.tableData.currentx - 1) * this.perPage) + this.$refs.tableData.datax.length
        }else{
            return ((this.$refs.tableData.currentx) * this.perPage)
        }
      }
    }
  }
};
</script>
